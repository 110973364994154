import {
	CheckboxGroup,
	Checkbox,
} from 'vant';
import {
	saveQuestion
} from '@/api/index.js';

export default {
	components: {
		[Checkbox.name]: Checkbox,
		[CheckboxGroup.name]: CheckboxGroup,
	},
	data() {
		return {
			checkedValue: [], // 多选选中项 ['1', '2', ...]
			items: [],
			list: {
				zh: [
          {
            id: '01',
            name: '1、深圳国际家具设计展',
            notes: '(原创设计/睡眠空间/现代客餐厅/当代简奢/极简风格/全屋定制/全屋智能/高端实木定制/高级整装定制/儿童空间定制)',
            checked: false,
            disabled: false
          },
          {
            id: '02',
            name: '2、深圳国际住宅展',
            notes: '(住宅公园/居住形态/创新户型/精装设计/内装工艺/创意建材/生活方式/产业论坛)',
            checked: false,
            disabled: false
          },
          {
            id: '03',
            name: '3、国际公建设计艺术展',
            notes: '(办公/教育/医养/文化场域)',
            checked: false,
            disabled: false
          },
          {
            id: '04',
            name: '4、ON LIGHT 光·生活展',
            notes: '(家居照明/商业照明/智能照明/工业照明/酒店照明/办公照明/照明设计机构)',
            checked: false,
            disabled: false
          },
          {
            id: '05',
            name: '5、新消费生活展',
            notes: '(艺术生活/艺术软饰/东方美学/创意设计/生活市集/设计师单品)',
            checked: false,
            disabled: false
          },
          {
            id: '06',
            name: '6、设计灵感·家居材料展',
            notes: '(创新材料/面料辅料/五金配件/家居面料/家具配套)',
            checked: false,
            disabled: false
          },
          {
            id: '07',
            name: '7、SMART智家展',
            notes: '(全宅智能/智慧睡眠/智慧厨卫/智慧办公/智慧娱乐/智慧新零售)',
            checked: false,
            disabled: false
          },
          {
            id: '08',
            name: '8、Design Dome设计万象',
            notes: '(国际当代家具/高定/灯饰/厨卫/设计机构/家居饰品/创新材料)',
            checked: false,
            disabled: false
          }
        ],
        
        en: [
          {
            id: '01',
            name: 'Turnkey Home',
            checked: false,
            disabled: false
          },
          {
            id: '02',
            name: 'Modern Luxury',
            checked: false,
            disabled: false
          },
          {
            id: '03',
            name: 'Customized',
            checked: false,
            disabled: false
          },
          {
            id: '04',
            name: 'Contemporary Design',
            checked: false,
            disabled: false
          },
          {
            id: '05',
            name: 'Soft Furnishings',
            checked: false,
            disabled: false
          },
          {
            id: '06',
            name: 'Solid Wood',
            checked: false,
            disabled: false
          },
          {
            id: '07',
            name: 'Workplace',
            checked: false,
            disabled: false
          },
          {
            id: '08',
            name: 'On Light',
            checked: false,
            disabled: false
          },
          {
            id: '09',
            name: 'Lifestyle & Décor',
            checked: false,
            disabled: false
          },
          {
            id: '10',
            name: 'Materials & Components',
            checked: false,
            disabled: false
          },
          {
            id: '11',
            name: 'Smart Home',
            checked: false,
            disabled: false
          },
          {
            id: '12',
            name: 'Mattress & Bedding',
            checked: false,
            disabled: false
          },
          {
            id: '13',
            name: 'Design Dome (Parallel Exhibition)',
            checked: false,
            disabled: false
          }
        ]
			}
		}
	},
	created() {
		this.setPageFillData()
	},
	methods: {
		setPageFillData() {
			// let keys = Object.entries(this.data).filter(e => e.includes('answer')); // ["answer01", "answer02", ..., "answer13"]
			// 筛选出被选中的选项
			Object.entries(this.data).forEach( e => {
				if (e[0].includes('answer') && !!e[1]) {
					this.checkedValue.push(e[0].slice(-2))
				}
			})
		},
		nextStep() {

			// 校验是否进行了问卷回答
			if (this.checkedValue.length === 0) {
				this.$toast(this.lang === 'en' ? 'Please select at least one' : '请至少选择一项');
				return;
			}
		
			this.$toast.loading({
				loadingType: 'spinner',
				forbidClick: true,
				duration: 0,
			});
			
			let obj = {};
			this.items.map(e => {
				obj['answer' + e.id] = this.checkedValue.includes(e.id); // obj.answer01 = 1
			});
			
			let params = {
				unionid: this.unionid || '',
				lang: this.lang == 'zh' ? 0 : 1, // 0-中文, 1-英文
				...obj
			}
		
			saveQuestion(params).then(res => {
				this.setPageData(this.path, obj); // 更新该页的回填数据
				this.pageSwitch(res.data); // 页面转换
			}).catch(err => {
				err ? this.$toast(err.message) : this.$toast.clear();
			});
		}
	}
}
