<template>
	<div class="page">
		<div class="container">

			<div class="content">
				<div class="title">请完成以下问题</div>
				<div class="label" :style="{color: style.btnColor}">您主要感兴趣的内容有(可多选)：</div>
				<van-checkbox-group v-model="checkedValue" icon-size="24px" :checked-color="style.btnColor">
					<van-checkbox v-for="(item,index) in items" :key="index" :name="item.id">
						<template #default>
							<div class="">
								<div class="item-name">{{item.name}}</div>
								<div class="item-notes">{{item.notes}}</div>
							</div>
						</template>
					</van-checkbox>
				</van-checkbox-group>
			</div>

			<van-button type="primary" block :color="style.btnColor" class="radius" @touchstart="nextStep">下一步</van-button>
		</div>

		<div class="footer">
			<bottom :mode="style.mode" :level="style.level"></bottom>
		</div>
	</div>
</template>

<script>

	import commonMixin from '@/view/process/mixin/common.js'
	import questionMixin from '@/view/process/mixin/question.js'

	export default {
		mixins: [commonMixin, questionMixin],
		data() {
			return {
				
			}
		},
		created() {
			this.setLanguge('zh'); // 设置语言环境
			this.items = this.list.zh;
		},
		methods: {
		
		},
	}
</script>

<style lang="less" scoped>
	@color: #9F9D9D;
	
	.container {
		margin: 5vh auto;
		
		.title {
			text-align: center;
			color: @color;
			font-size: 20px;
			margin: 2vh auto 5vh;
		}
		
		.content {
			width: 320px;
			margin: 0 auto 6vh;

			.label {
				font-size: 16px;
				margin-bottom: 20px;
			}

			/deep/ .van-checkbox {
				color: #1B1B1B;
				font-size: 14px;
				display: flex;
				align-items: flex-start;
				margin-bottom: 18px;

				.van-icon {
					font-size: 15px;
				}

			}

			.item-name {
				font-size: 14px;
				letter-spacing: 1px;
			}
			.item-notes {
				margin-top: 7px;
				padding-left: 20px;
				font-size: 12px;
				letter-spacing: 1px;
				line-height: 20px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: @color;
			}
		}
	}
</style>
